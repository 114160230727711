import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Section, Strong, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Послуги з прибирання Qivanaplay
			</title>
			<meta name={"description"} content={"Зробіть свій простір блискучим без особливих зусиль"} />
			<meta property={"og:title"} content={"Головна | Послуги з прибирання Qivanaplay"} />
			<meta property={"og:description"} content={"Зробіть свій простір блискучим без особливих зусиль"} />
			<meta property={"og:image"} content={"https://qivanaplay.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://qivanaplay.com/img/392758.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://qivanaplay.com/img/392758.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://qivanaplay.com/img/392758.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://qivanaplay.com/img/392758.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://qivanaplay.com/img/392758.png"} />
			<meta name={"msapplication-TileImage"} content={"https://qivanaplay.com/img/392758.png"} />
			<meta name={"msapplication-TileColor"} content={"https://qivanaplay.com/img/392758.png"} />
		</Helmet>
		<Components.Header />
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://qivanaplay.com/img/1.jpg) top/cover"
			height="100%"
			padding="200px 0 50px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Box margin="-16px -16px -16px -16px" display="flex">
				<Box display="flex" padding="16px 16px 16px 16px" width="75%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text
							color="--lightD2"
							letter-spacing="1px"
							text-transform="uppercase"
							margin="0"
							font="--headline1"
						>
							Послуги з прибирання Qivanaplay
						</Text>
						<Text as="h1" font="--headline3" md-font="--headline2" margin="10px 0">
						У клінінговій компанії Qivanaplay ми віримо, що зможемо перетворити ваші оселі на безтурботні гавані. Наша віддана команда тут, щоб позбавити вас рутинної роботи з прибирання, залишаючи вам більше часу, щоб насолоджуватися речами, які справді мають значення. З нальотом турботи та професіоналізму ми гарантуємо, що кожен куточок засяє яскравістю, як і випливає з нашої назви. Ласкаво просимо до чистої, яскравої оселі.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box text-align="center" margin="296px 0 0 0">
				<Text margin="8px 0" text-transform="uppercase">
				Чому варто обрати Qivanaplay?
				</Text>
				<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="flex-start"
				display="flex"
				width="50%"
			>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
					
					<Strong>
					Експертиза в кожному куточку{" "}
					</Strong>
					Наша команда навчена ретельно прибирати будь-які приміщення, від затишних квартир до просторих будинків. Ми використовуємо новітні методи прибирання та екологічно чисті засоби для досягнення відмінних результатів.

					<Strong>
						{" "}
					</Strong>
					<br /><br />
					<Strong>
					Пристосовані до ваших потреб{" "}
					</Strong>
					Ми розуміємо, що кожна оселя унікальна. Саме тому ми пропонуємо індивідуальні плани прибирання відповідно до вашого графіку та вподобань, гарантуючи, що ваш простір буде доглянутий саме так, як вам подобається.{" "}
					<br /><br />
					<Strong>
					Довіра та безпека понад усе{" "}
					</Strong>
					Ваш спокій - наш пріоритет. Компанія Qivanaplay повністю застрахована, що гарантує вам безтурботне обслуговування кожного разу, коли ми заходимо до вас додому.
					<br /><br />
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://qivanaplay.com/img/2.jpg"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
			Зв'яжіться з нами
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			Готові зробити свою оселю блискучою? Зв'яжіться з нами, і давайте обговоримо, як ми можемо вивести ваш простір на новий рівень чистоти.
			</Text>
			<Button
				font="--lead"
				margin="20px"
				type="link"
				text-decoration-line="initial"
				href="/contact"
			>
				Контакти
			</Button>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652f9cc5dbe26f0020fd00a7"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});